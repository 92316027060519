body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
  font-family: 'Inconsolata', monospace;
  color: #2a192a; }

h1, h2, h3, h4 {
  margin-top: 0; }

a {
  color: #2d5cab; }

.App {
  text-align: center; }

.hide {
  display: none; }

img {
  padding: 10px;
  box-sizing: border-box; }
  @media only screen and (max-width: 782px) {
    img {
      width: 100%; } }

.App-header {
  background-color: #f2f1dc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); }

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #deddc8; }
  .section .space-below {
    margin-bottom: 60px; }
    @media only screen and (max-width: 782px) {
      .section .space-below {
        margin-bottom: 20px; } }
  @media only screen and (max-width: 782px) {
    .section {
      padding: 20px; } }

.section.afterdark {
  background-color: #161113;
  color: rgba(255, 255, 255, 0.8); }

header {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }
  header.away {
    top: auto;
    height: 50px;
    width: 55px;
    bottom: 20px;
    right: 20px; }
    header.away .content-wrap {
      height: 50px;
      width: 55px;
      transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }
      header.away .content-wrap svg {
        height: 50px;
        width: 55px;
        transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); }
      header.away .content-wrap .logo-wrap {
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px; }
      header.away .content-wrap .hello {
        display: none; }
      header.away .content-wrap .bio {
        display: none; }
      header.away .content-wrap .avatar {
        display: none; }
  header.dark .logo {
    mix-blend-mode: screen; }
  header.dark .avatar {
    display: none; }

.content-wrap {
  width: 215px;
  height: 196px;
  margin: 0 auto;
  position: relative;
  z-index: 9999; }

.logo-wrap {
  display: block;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute; }

.hello {
  display: inline;
  position: absolute;
  opacity: 0;
  left: 0;
  top: -10px;
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s;
  font-size: 34px;
  white-space: nowrap; }
  @media only screen and (max-width: 782px) {
    .hello {
      white-space: normal;
      width: 100px;
      top: -30px; } }

.dark .hello,
.dark .bio {
  color: #eadae5; }

.bio {
  position: absolute;
  top: 50px;
  left: 20px;
  opacity: 0;
  font-size: 16px;
  transition: top 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s;
  margin-left: -30px;
  right: -10px; }

.avatar {
  position: absolute;
  z-index: -1;
  top: 50px;
  left: 50px;
  opacity: 0;
  border: 7px solid #f2f1dc;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  transition: top 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s;
  background-position: center;
  background-size: cover; }

.content-wrap:hover .hello {
  left: 145px;
  opacity: 1; }

.content-wrap:hover .bio {
  top: 230px;
  opacity: 1; }

.content-wrap:hover .avatar {
  top: -130px;
  opacity: 1; }

@media only screen and (max-width: 782px) {
  .content-wrap .hello {
    left: 140px;
    opacity: 1; }
  .content-wrap .bio {
    top: 230px;
    opacity: 1; } }

.logo {
  opacity: 0.35; }
  .logo .st1 {
    transition: fill 0.3s; }

.logo:nth-child(1) .st1 {
  fill: rgba(206, 226, 156, 0.75); }

.logo:nth-child(2) .st1 {
  fill: rgba(255, 232, 157, 0.75); }

.logo:nth-child(3) .st1 {
  fill: rgba(254, 193, 134, 0.75); }

.logo:nth-child(4) .st1 {
  fill: rgba(248, 119, 69, 0.75); }

.logo:nth-child(5) .st1 {
  fill: rgba(241, 91, 95, 0.75); }

.logo:nth-child(6) .st1 {
  fill: rgba(189, 62, 92, 0.75); }

.logo:nth-child(7) .st1 {
  fill: rgba(186, 89, 160, 0.75); }

.logo:nth-child(8) .st1 {
  fill: rgba(121, 55, 154, 0.75); }

.logo:nth-child(9) .st1 {
  fill: rgba(70, 46, 96, 0.75); }

.logo:nth-child(10) .st1 {
  fill: rgba(67, 46, 144, 0.75); }

.logo:nth-child(11) .st1 {
  fill: rgba(59, 64, 150, 0.75); }

.logo:nth-child(12) .st1 {
  fill: rgba(45, 92, 171, 0.75); }

.logo:nth-child(13) .st1 {
  fill: rgba(58, 153, 216, 0.75); }

.logo:nth-child(14) .st1 {
  fill: rgba(165, 219, 244, 0.75); }

.dark .logo:nth-child(1) .st1 {
  fill: rgba(59, 64, 150, 0.75); }

.dark .logo:nth-child(2) .st1 {
  fill: rgba(45, 92, 171, 0.75); }

.dark .logo:nth-child(3) .st1 {
  fill: rgba(58, 153, 216, 0.75); }

.dark .logo:nth-child(4) .st1 {
  fill: rgba(165, 219, 244, 0.75); }

.dark .logo:nth-child(5) .st1 {
  fill: rgba(206, 226, 156, 0.75); }

.dark .logo:nth-child(6) .st1 {
  fill: rgba(255, 232, 157, 0.75); }

.dark .logo:nth-child(7) .st1 {
  fill: rgba(254, 193, 134, 0.75); }

.dark .logo:nth-child(8) .st1 {
  fill: rgba(248, 119, 69, 0.75); }

.dark .logo:nth-child(9) .st1 {
  fill: rgba(241, 91, 95, 0.75); }

.dark .logo:nth-child(10) .st1 {
  fill: rgba(189, 62, 92, 0.75); }

.dark .logo:nth-child(11) .st1 {
  fill: rgba(186, 89, 160, 0.75); }

.dark .logo:nth-child(12) .st1 {
  fill: rgba(121, 55, 154, 0.75); }

.dark .logo:nth-child(13) .st1 {
  fill: rgba(70, 46, 96, 0.75); }

.dark .logo:nth-child(14) .st1 {
  fill: rgba(67, 46, 144, 0.75); }

.logo {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform-origin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform-origin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform-origin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform-origin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }

.logos {
  margin-top: 30px;
  margin-bottom: 30px; }

.App-link {
  color: #61dafb; }

.fp-tableCell {
  height: 100% !important; }

.row {
  display: flex;
  align-items: stretch;
  margin: 0 auto;
  width: 1000px;
  text-align: left; }
  @media only screen and (max-width: 782px) {
    .row {
      width: 100%;
      flex-direction: column;
      text-align: center; } }
  .row .col {
    padding: 0 20px;
    flex: 1 1; }
    .row .col img {
      width: 100%;
      height: auto; }

@media only screen and (max-width: 782px) {
  .clients .row, .mylogos .row, .sites .row {
    flex-direction: row; } }

.clients .row .col, .mylogos .row .col, .sites .row .col {
  padding: 0;
  margin: 0;
  position: relative; }
  .clients .row .col:hover img, .mylogos .row .col:hover img, .sites .row .col:hover img {
    opacity: 0.1;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
    @media only screen and (max-width: 782px) {
      .clients .row .col:hover img, .mylogos .row .col:hover img, .sites .row .col:hover img {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1); } }
  .clients .row .col:hover .text, .mylogos .row .col:hover .text, .sites .row .col:hover .text {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
    @media only screen and (max-width: 782px) {
      .clients .row .col:hover .text, .mylogos .row .col:hover .text, .sites .row .col:hover .text {
        opacity: 0; } }
  .clients .row .col img, .mylogos .row .col img, .sites .row .col img {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
  .clients .row .col .text, .mylogos .row .col .text, .sites .row .col .text {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.8);
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
    border-radius: 6px; }
  .clients .row .col .corner-ribbon, .mylogos .row .col .corner-ribbon, .sites .row .col .corner-ribbon {
    width: 180px;
    position: absolute;
    top: 25px;
    right: -50px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: lightgray;
    color: darkgray; }
    .clients .row .col .corner-ribbon.onice, .mylogos .row .col .corner-ribbon.onice, .sites .row .col .corner-ribbon.onice {
      background: lightblue;
      color: darkblue; }
    .clients .row .col .corner-ribbon.live, .mylogos .row .col .corner-ribbon.live, .sites .row .col .corner-ribbon.live {
      background: deeppink;
      color: white; }

.clients .row .col .text {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.8); }

.mylogos .row .col .text {
  background: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.8); }

@media only screen and (max-width: 782px) {
  .sites .row {
    flex-direction: column; } }

.sites .row .col {
  margin: 10px; }
  @media only screen and (max-width: 782px) {
    .sites .row .col {
      margin: 0px; } }
  .sites .row .col img {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 782px) {
      .sites .row .col img {
        display: none; } }
  .sites .row .col a {
    display: block;
    margin-bottom: 0px; }
    @media only screen and (max-width: 782px) {
      .sites .row .col a {
        margin-bottom: 0px; } }
  .sites .row .col img {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
  @media only screen and (max-width: 782px) {
    .sites .row .col br {
      display: none; } }
  .sites .row .col .text {
    background: rgba(255, 255, 255, 0);
    color: rgba(0, 0, 0, 0.8);
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px; }
    @media only screen and (max-width: 782px) {
      .sites .row .col .text {
        position: static;
        opacity: 1;
        height: auto;
        padding: 0; } }
    .sites .row .col .text h3 {
      margin-bottom: 20px; }
    @media only screen and (max-width: 782px) {
      .sites .row .col .text p {
        display: none; } }
  .sites .row .col:hover img {
    opacity: 0; }

.video video {
  width: 80%; }
  @media only screen and (max-width: 782px) {
    .video video {
      width: 100%; } }

.stacks .row .col {
  border-left: 1px solid #2a192a; }
  .stacks .row .col:first-child {
    border-width: 0; }
  @media only screen and (max-width: 782px) {
    .stacks .row .col {
      margin-bottom: 10px;
      border-width: 0px; } }
  @media only screen and (max-width: 782px) {
    .stacks .row .col h4 {
      margin-bottom: 3px; } }
  @media only screen and (max-width: 782px) {
    .stacks .row .col:first-child {
      display: none; } }
  .stacks .row .col li {
    margin: 0 0 8px 0;
    font-size: 14px;
    line-height: 1em; }
    @media only screen and (max-width: 782px) {
      .stacks .row .col li {
        line-height: 1em;
        margin: 0 0 0 0;
        padding: 0; } }
    .stacks .row .col li a {
      color: #2a192a;
      text-decoration: none; }
      .stacks .row .col li a:hover {
        color: #2d5cab;
        text-decoration: underline; }

@media only screen and (max-width: 782px) {
  .stacks {
    font-size: 12px; } }

.sites img {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 782px) {
    .sites img {
      display: none; } }

.sites a {
  display: block;
  margin-bottom: 20px; }
  @media only screen and (max-width: 782px) {
    .sites a {
      margin-bottom: 0px; } }

.contact .form {
  margin-top: 30px; }
  .contact .form .inputs {
    height: 0;
    overflow: hidden;
    transition: height 0.3s; }
  .contact .form.open .inputs {
    height: 200px; }
  .contact .form.open button {
    display: inline; }
  .contact .form.open .say {
    display: none; }
  .contact .form form textarea,
  .contact .form form input[type="email"],
  .contact .form form input[type="text"] {
    width: 300px;
    font-size: 18px;
    padding: 10px;
    border: 0;
    display: inline-block; }
  .contact .form form button {
    display: none; }
  .contact .form form .say {
    display: inline; }

button {
  padding: 10px;
  font-size: 18px;
  border: 1px solid #2a192a;
  background: transparent;
  transition: all 0.3s;
  cursor: pointer; }
  button:hover {
    background: #2a192a;
    color: white; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  ul li {
    line-height: 1.3em; }

.social a {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 10px; }
  .social a svg {
    width: 100%;
    fill: #2a192a; }

.st0 {
  fill: #019E9F; }

.st1 {
  fill: #812292; }

.st2 {
  fill: #AD77BE; }

.st3 {
  fill: #48EA61; }

.st4 {
  fill: #DE4A7A; }

.st5 {
  fill: #FFFFFF; }

